
import { injectRouter } from '@/router';
import { kickOut, Section, sections } from '@/router/guards';
import { authStore } from '@/store/auth';
import { useStore } from '@/store/lib/store';
import { defineComponent, onMounted, PropType } from 'vue';

export default defineComponent({
  name: 'Auth',
  props: {
    section: { type: String as PropType<Section>, required: true },
    accessToken: String
  },
  setup(props) {
    const router = injectRouter();
    const auth = useStore(authStore);

    async function redirectIn() {
      if (props.section === 'applicant') {
        await router.replace({ name: 'applicant' });
      } else if (props.section === 'program') {
        await router.replace({ name: 'program' });
      } else if (props.section === 'admin') {
        // TODO Add missing routes
        // eslint-disable-next-line no-console
        console.error('Admin routes not implemented yet');
      } else {
        throw new Error('Unexpected app section requested');
      }
    }

    onMounted(async () => {
      if (!sections.includes(props.section) || props.accessToken === undefined) {
        kickOut(props.section);
        return;
      }

      try {
        await auth.logIn(props.accessToken);
        await redirectIn();
      } catch (error) {
        kickOut(props.section);
      }
    });
  }
});
